import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout.js";
import SEO from "../../components/seo";
import { navigate } from "gatsby-link";
import api from "../../api.js";
import { AuthContext } from "../../context/GlobalContextProvider.js";
import { Button } from "antd";
import PredioComponent from "../generics/predios.js";

export default function BivverExclusivity() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState();
  const context = useContext(AuthContext);
  useEffect(() => {
    getData();
    getPage();
  }, []);
  const getData = async () => {
    var response = await api.get("/predios?classe=BivverCExclusivity");
    setData(response.data);
  };

  const getPage = async () => {
    var response = await api.get("/bivver-exclusivity");
    setPage(response.data);
  };
  return (
    <Layout
      aboveFooter
      titlePage="Bivver Exclusivity"
      pageDesctiption={page?.conteudo}
      loading={!page}
    >
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Bivver Exclusivity"
      />
      {context?.user && (
        <Button
          className="mb-10"
          style={{ borderColor: "orange", color: "orange" }}
          danger
          onClick={() => navigate(`/edicao/bivverExclusivity`)}
        >
          Editar
        </Button>
      )}
      <div>
        <PredioComponent buildings={data} />
      </div>
    </Layout>
  );
}
